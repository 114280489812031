import React from "react";
import { Chip, ChipProps } from "@mui/material";
import moment from "moment";

import { Formatter } from "../utils";

interface Props extends ChipProps {
  startDate: Date;
}

export const LiveDuration = ({ startDate = new Date(), ...rest }: Props) => {
  return (
    <Chip
      size="small"
      {...rest}
      label={Formatter.getDuration(moment(Date.now()).diff(startDate, "second"))}
      sx={{
        borderRadius: 1,
        mr: 1,
        fontSize: { sm: 16, md: 20 },
        fontWeight: "600",
        bgcolor: "#00000080",
        py: { sm: 0.5, md: 1 },
        fontFamily: "monospace",
        ...rest?.sx,
      }}
    />
  );
};
